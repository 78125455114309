'use client'

import { useEffect, ReactNode } from 'react'

import { replaceUrlHost, urlWithParams } from 'libs/utils/url'
import { AccessChannel } from 'constants/index'

import { FaqEntryType } from 'constants/faq-entry'
import useLocation from 'hooks/useLocation'
import useFetch from 'hooks/useFetch'
import { getFaqEntryByType } from 'data/api'
import { transformFaqEntryDto } from 'data/transformers/faq-entry'

type Props = {
  accessChannel?: AccessChannel
  type: FaqEntryType
  render: (faqUrl: string, faqEntryId: number) => ReactNode
}

const FaqEntryUrl = ({ type, render, accessChannel }: Props) => {
  const { host } = useLocation()

  const { fetch: fetchFaqEntryByType, data: faqEntryResponse } = useFetch(getFaqEntryByType)
  const faqEntry = faqEntryResponse?.faq_entry
    ? transformFaqEntryDto(faqEntryResponse.faq_entry)
    : null

  useEffect(() => {
    fetchFaqEntryByType(type)
  }, [type, fetchFaqEntryByType])

  if (!faqEntry?.url) return null

  const hostUrl = `http://${host}`
  const replacedUrl = replaceUrlHost(faqEntry.url, hostUrl)

  const finalUrl = accessChannel
    ? urlWithParams(replacedUrl, { access_channel: accessChannel })
    : replacedUrl

  return <>{render(finalUrl, faqEntry.id)}</>
}

export default FaqEntryUrl
