'use client'

import { useState, useEffect } from 'react'
import { noop } from 'lodash'

import NotificationPortal from 'components/NotificationPortal'

export enum CloseType {
  CloseButtonClick = 'close_button_click',
  BodyClick = 'body_click',
  Auto = 'auto',
}

type OwnProps = {
  onNotificationClose?: (closeType: CloseType | undefined) => void
}

type Props = OwnProps & ComponentProps<typeof NotificationPortal>

const Notification = ({ onNotificationClose = noop, ...props }: Props) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])

  const handleNotificationClose = (closeType: CloseType | undefined) => {
    setShow(false)

    if (onNotificationClose) onNotificationClose(closeType)
  }

  if (!show) return null

  return <NotificationPortal {...props} onClose={handleNotificationClose} />
}

export { Notification }

export default Notification
