'use client'

import { Component } from 'react'
import ReactDOM from 'react-dom'
import { Notification } from '@vinted/web-ui'
import classNames from 'classnames'

import { NotificationPosition } from 'types/components'

type OwnProps = {
  position: NotificationPosition
}

type Props = OwnProps & ComponentProps<typeof Notification>

class NotificationPortal extends Component<Props> {
  static defaultProps = {
    position: NotificationPosition.Bottom,
  }

  render() {
    const { position } = this.props

    if (position === NotificationPosition.Parent)
      return (
        <div className="u-z-index-notification">
          <Notification {...this.props} />
        </div>
      )

    const classes = classNames('u-z-index-notification', {
      'u-sticky-bottom u-ui-padding-bottom-x-large': position === NotificationPosition.Bottom,
      'u-fixed-top u-ui-padding-top-x-large': position === NotificationPosition.Top,
      'u-fixed-top u-right u-ui-padding-top-x5-large': position === NotificationPosition.TopRight,
    })

    return ReactDOM.createPortal(
      <div className="u-flexbox u-align-items-center u-justify-content-center">
        <div className={classes}>
          <Notification {...this.props} />
        </div>
      </div>,
      document.body,
    )
  }
}

export default NotificationPortal
