import { JSXElementConstructor, ReactNode } from 'react'

type AnyObject = Record<string, unknown>

type ExtractKeyByValueType<T extends AnyObject, V> = {
  [K in keyof T]: V extends T[K] ? K : never
}[keyof T]

type OptionalProperties<T extends AnyObject> = Partial<Pick<T, ExtractKeyByValueType<T, undefined>>>

export const withLayout =
  <LP extends AnyObject>(
    Layout: JSXElementConstructor<{ children: ReactNode } & Omit<LP, 'children'>>,
  ) =>
  <CP extends AnyObject>(
    WrappedComponent: JSXElementConstructor<Omit<CP, '_layout'>>,
    customLayoutProps?: OptionalProperties<LP>,
  ) =>
  ({ _layout: layoutProps, ...props }: CP & { _layout: Omit<LP, 'children'> }) => (
    <Layout {...layoutProps} {...customLayoutProps}>
      <WrappedComponent {...props} />
    </Layout>
  )
